.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.foreigndiv {
  text-align: center;
}

.foreigndiv p {
  margin: 0;
  padding: 0;
  margin-right: 10px;
  margin-top: 3px;
  display: inline-block;
  cursor: pointer;
}

.selected_phrase {
  font-weight: 600;
}

mark {
  background: rgb(146, 146, 146);
  color: black;
}

.topic_parent {
  box-shadow: 3px 4px 4px -2px #cccccc;
}
.topic_view_container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  height: 8px;
}
.topic_view_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 2px;
  background-color: rgba(39, 39, 39, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}